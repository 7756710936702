import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useDispatch } from 'store';
import Button from 'application/components/button';
import { MapUtils } from 'application/utils/MapUtils';
import towerEffects from 'store/effects/tower.effects';
import { selectTowers } from 'store/selectors/tower.selectors';
import {
  selectInfraProductContexts,
  selectTowers as selectInfrastructureTowers,
} from 'store/selectors/infrastructure.selectors';
import { selectCurrentFarm } from 'store/selectors/farm-infra.selectors';
import infrastructureEffects from 'store/effects/infrastructure.effects';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

const TowerSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > *:not(:first-child) {
    margin-top: -6px;
  }
`;

const AssignDevicesDialog: FunctionComponent<DeviceActionDialogProps> = (props) => {
  const { open, onClose, devices } = props;
  const [selectedTowerId, setSelectedTowerId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const currentFarm = useSelector(selectCurrentFarm);
  const currentFarmTowers = useSelector(selectTowers);
  const currentInfrastructureTowers = useSelector(selectInfrastructureTowers);
  const currentInfraProductContexts = useSelector(selectInfraProductContexts);

  /*
   * NOTE: The towers in infrastructure service uses uuid as id.
   * Tower service's tower id is the name of towers from infrastructure service.
   */
  const infrastructureTowersById = MapUtils.keyBy(currentInfrastructureTowers, (it) => it.name);
  const infraProductContextById = MapUtils.keyBy(currentInfraProductContexts, (it) => it.id);

  const handleAssignInfraProductContexts = async (towerId: string, infraProductContextId: string) => {
    const infrastructureTower = infrastructureTowersById.get(towerId);
    if (infrastructureTower == null) return;

    const infraProductContext = infraProductContextById.get(infraProductContextId);
    if (infraProductContext == null) return;

    if (infraProductContext.groupId.length === 0) {
      dispatch(
        infrastructureEffects.updateInfraProductContextById({
          id: infraProductContext.id,
          updateInfraProductContextRequest: { towerId: infrastructureTower.id },
        })
      );
      return;
    }

    infrastructureEffects.updateAllInfraProductContextTowerIdByGroupId({
      towerId,
      groupId: infraProductContext.groupId,
    });
  };

  const handleAssignDevices = async () => {
    if (!selectedTowerId) return;

    devices.forEach(async (device) => {
      dispatch(
        towerEffects.updateDeviceContextByIdAndFarmId({
          id: device.id,
          farmId: currentFarm,
          updateDeviceContextDto: { towerId: selectedTowerId, name: selectedTowerId },
        })
      );
      await handleAssignInfraProductContexts(selectedTowerId, device.id);
    });
    onClose();
  };

  useEffect(() => {
    setSelectedTowerId(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Re-assign Devices To Tower</DialogTitle>
      <DialogContent>
        <Typography style={{ fontWeight: 500, fontSize: 18 }}>Tower to select:</Typography>
        <TowerSelectWrapper>
          {currentFarmTowers.map((tower) => (
            <FormControlLabel
              key={tower.id}
              label={tower.id}
              control={
                <Radio
                  color="primary"
                  checked={selectedTowerId === tower.id}
                  onChange={() => setSelectedTowerId(tower.id)}
                  value={tower.id}
                />
              }
            />
          ))}
        </TowerSelectWrapper>

        <Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom={1}>
          <Button disabled={!selectedTowerId} text="Re-assign devices" onClick={handleAssignDevices} />
          <Button backgroundColour="#616161" text="Cancel" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AssignDevicesDialog;
